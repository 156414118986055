<template>
    <div class="error-payment">
        <div class="error-payment__container">
            <div class="error-payment_icon">
                <error />
            </div>
            <p class="error-payment__title">
                <span>Payment failed!</span>
            </p>
            <p class="error-payment__text">
                Transaction has failed
                <br>
                <span v-if="message && message.isExpiredBatch">
                    Your invoice has expired. Please try again
                </span>
                <span v-else>
                    Please use a different card or another way.
                </span>
            </p>
            <div class="error-payment__buttons">
                <custom-button
                    default
                    class="button btn-base_colored"
                    :type="'submit'"
                    :loading="createBatchLoading"
                    @on-btn-click="tryAgain"
                >
                    Try again
                </custom-button>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import Error from '@/components/icons/Error.vue';
import { eventBus } from '@/helpers/event-bus/'
// VUEX
import {
    ACTION_GET_CART_FROM_BACKEND,
    ACTION_CREATE_BATCH
} from '@/store/modules/checkout/action-types'
import {
    SET_PAYMENT_ERROR
} from '@/store/modules/checkout/mutation-types.js'

const {
    mapActions: mapCheckoutActions,
    mapMutations: mapCheckoutMutations
} = createNamespacedHelpers('checkout');

export default {
    name: 'ErrorPayment',
    components: {
        Error
    },
    metaInfo: {
        title: 'Payment error!'
    },
    data() {
        return {
            message: '',
            createBatchLoading: false
        }
    },
    computed: {
        ...mapGetters('checkout', [
            'getterPaymentError'
        ])
    },
    async created() {
        this.message = this.getterPaymentError
        this[SET_PAYMENT_ERROR](null)
        await this[ACTION_GET_CART_FROM_BACKEND]()
    },
    methods: {
        ...mapCheckoutMutations([
            SET_PAYMENT_ERROR
        ]),
        ...mapCheckoutActions([
            ACTION_GET_CART_FROM_BACKEND,
            ACTION_CREATE_BATCH
        ]),
        async tryAgain() {
            try {
                this.createBatchLoading = true
                const batch = await this[ACTION_CREATE_BATCH]()
                if (batch.status === 'UNPAID') {
                    this.$router.push({ name: 'checkout-batch', params: { batchid: batch.batchid } })
                } else {
                    this.$router.push({ name: 'checkout_success' })
                }
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.createBatchLoading = false
            }
        }
    }
}
</script>

<style lang="scss">
.error-payment {
    width: 100vw;
    height: 70vh;
    min-height: 700px;
    min-width: 375px;
    display: flex;
    align-items: center;
    justify-content: center;
    &_icon{
        width: 90px;
        height: 90px;
        border-radius: 50%;
        border: 2px solid $error-color;
        position: relative;
        svg {
            width: 50px;
            height: 50px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            margin-left: 0 !important;
        }
    }
    &__container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    &__title{
        font-style: normal;
        font-weight: 900;
        font-size: 56px;
        line-height: 70px;
        margin: 20px 0;
        color: $error-color;
    }
    &__text {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 40px;
        &-error {
            color: $error-color;
        }
    }
    &__buttons {
        margin-top: 40px;
        display: flex;
    }
}
</style>
