<template>
    <svg
        width="175"
        height="120"
        viewBox="0 0 175 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M41.1754 0H161.554C168.789 0 174.674 5.28259 174.674 11.777V108.217C174.674 114.713 168.789 119.998 161.554 119.998H103.869L97.9577 120H41.1754C33.9441 120 28.0625 114.715 28.0625 108.219V94.4127C28.0625 92.7812 29.3846 91.4571 31.0181 91.4571C32.6515 91.4571 33.9736 92.7812 33.9736 94.4127V108.219C33.9736 111.456 37.2051 114.089 41.1754 114.089H161.554C165.528 114.089 168.763 111.456 168.763 108.219V11.777C168.763 8.54159 165.53 5.91114 161.554 5.91114H41.1754C37.2858 5.91114 34.0544 8.48839 33.9736 11.6568V36.8303C33.9736 38.4638 32.6515 39.7859 31.0181 39.7859C29.3846 39.7859 28.0625 38.4638 28.0625 36.8303V11.5819C28.228 5.05402 33.9874 0 41.1754 0Z"
            fill="url(#paint0_linear)"
        />
        <path
            d="M81.6802 48.2251H145.03C146.661 48.2251 147.985 46.903 147.985 45.2695C147.985 43.6361 146.661 42.314 145.03 42.314H81.6802C80.0467 42.314 78.7246 43.6361 78.7246 45.2695C78.7246 46.903 80.0467 48.2251 81.6802 48.2251Z"
            fill="url(#paint1_linear)"
        />
        <path
            d="M81.6802 66.9419H145.03C146.661 66.9419 147.985 65.6198 147.985 63.9863C147.985 62.3529 146.661 61.0308 145.03 61.0308H81.6802C80.0467 61.0308 78.7246 62.3529 78.7246 63.9863C78.7246 65.6198 80.0467 66.9419 81.6802 66.9419Z"
            fill="url(#paint2_linear)"
        />
        <path
            d="M81.6802 86.6929H145.03C146.661 86.6929 147.985 85.3688 147.985 83.7373C147.985 82.1058 146.661 80.7817 145.03 80.7817H81.6802C80.0467 80.7817 78.7246 82.1058 78.7246 83.7373C78.7246 85.3688 80.0467 86.6929 81.6802 86.6929Z"
            fill="url(#paint3_linear)"
        />
        <path
            d="M30.4146 73.1862C31.1772 73.1862 31.9338 72.8926 32.5052 72.3212L75.2073 29.6172C76.3619 28.4625 76.3619 26.5907 75.2073 25.438C74.0526 24.2834 72.1827 24.2834 71.0281 25.438L30.0935 66.3746L4.66967 48.2747C3.3377 47.3289 1.4954 47.6402 0.547643 48.9683C-0.398139 50.2983 -0.0887889 52.1445 1.24319 53.0903L28.7044 72.6384C29.2186 73.0069 29.8196 73.1862 30.4146 73.1862Z"
            fill="url(#paint4_linear)"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="28.0625"
                y1="9.13042"
                x2="182.071"
                y2="24.9033"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FF9D39" />
                <stop
                    offset="1"
                    stop-color="#FF5925"
                />
            </linearGradient>
            <linearGradient
                id="paint1_linear"
                x1="78.7246"
                y1="42.7637"
                x2="116.144"
                y2="79.5169"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FF9D39" />
                <stop
                    offset="1"
                    stop-color="#FF5925"
                />
            </linearGradient>
            <linearGradient
                id="paint2_linear"
                x1="78.7246"
                y1="61.4805"
                x2="116.144"
                y2="98.2337"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FF9D39" />
                <stop
                    offset="1"
                    stop-color="#FF5925"
                />
            </linearGradient>
            <linearGradient
                id="paint3_linear"
                x1="78.7246"
                y1="81.2315"
                x2="116.144"
                y2="117.985"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FF9D39" />
                <stop
                    offset="1"
                    stop-color="#FF5925"
                />
            </linearGradient>
            <linearGradient
                id="paint4_linear"
                x1="7.42675e-07"
                y1="28.2709"
                x2="79.3835"
                y2="38.684"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FF9D39" />
                <stop
                    offset="1"
                    stop-color="#FF5925"
                />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'SuccessfulPaymentIcon'
}
</script>
<style lang="scss">
defs > linearGradient > stop:first-child{
    stop-color: $second-gradient__color-first;
}
defs > linearGradient > stop:last-child{
    stop-color: $second-gradient__color-second;
}
</style>
