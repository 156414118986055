<template>
    <div class="successful-payment">
        <radius-loader v-if="loading" />
        <div
            v-else
            class="successful-payment__container"
        >
            <successful-payment-icon />
            <p class="successful-payment__title">
                <span>Great success!</span>
            </p>
            <p class="successful-payment__text">
                Your order is received.
            </p>
            <div class="successful-payment__buttons">
                <custom-button
                    default
                    class="button btn-base_colored"
                    :type="'submit'"
                    @on-btn-click="$router.push({ name: 'orders' })"
                >
                    Manage my projects
                </custom-button>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus/'
import { createNamespacedHelpers } from 'vuex'
import SuccessfulPaymentIcon from '@/components/icons/SuccessfulPaymentIcon.vue';
import RadiusLoader from '@/components/common/RadiusLoader.vue'

// VUEX
import {
    ACTION_GET_CART_FROM_BACKEND
} from '@/store/modules/checkout/action-types'
import {
    RESET
} from '@/store/modules/checkout/mutation-types'
import {
    ACTION_UPDATE_CLIENT_DATA
} from '@/store/modules/client/action-types.js'

const {
    mapMutations: mapOrderMutations
} = createNamespacedHelpers('order');
const {
    mapActions: mapCheckoutActions
} = createNamespacedHelpers('checkout');
const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client')

export default {
    name: 'SuccessfulPayment',
    components: {
        SuccessfulPaymentIcon,
        RadiusLoader
    },
    metaInfo: {
        title: 'Great success!'
    },
    data() {
        return {
            loading: false
        }
    },
    async created() {
        if (this.$route.query?.batch && this.$route.query?.hash) {
            try {
                this.loading = true
                await Api.get('/api/order-batch/pay/stripe/callback', {
                    batchid: this.$route.query.batch,
                    hash: this.$route.query.hash
                })
                await this.updateClientData()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
                this.$router.push('/checkout/error')
            } finally {
                this.loading = false
            }
        }
        try {
            this.resetOrderForm()
            await this[ACTION_GET_CART_FROM_BACKEND]()
        } catch (error) {
            eventBus.$emit('showSnackBar', error, 'error')
        }
    },
    methods: {
        ...mapOrderMutations({
            resetOrderForm: RESET
        }),
        ...mapCheckoutActions([
            ACTION_GET_CART_FROM_BACKEND
        ]),
        ...mapClientActions([
            ACTION_UPDATE_CLIENT_DATA
        ]),
        async updateClientData() {
            try {
                await this[ACTION_UPDATE_CLIENT_DATA]()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        }
    }
}
</script>

<style lang="scss">
.successful-payment {
    width: 100%;
    height: 70vh;
    min-height: 700px;
    min-width: 375px;
    background-color: #fafafa;
    &__container {
        max-width: 900px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        svg {
            margin-left: -28px;
        }
    }
    &__title {
        font-style: normal;
        font-weight: 900;
        font-size: 56px;
        line-height: 70px;
        margin-top: 40px;
        margin-bottom: 20px;
        text-align: center;
        display: flex;
        flex-direction: column;
    }
    &__text {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 40px;
    }
    &__buttons {
        margin-top: 40px;
        display: flex;
    }

    & .button:first-child {
        margin-right: 20px;
    }
}
</style>
